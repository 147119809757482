import React from 'react'
import { Link, SiteNotification, SiteNotifications } from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const SiteNotificationExample2 = () => (
  <SiteNotifications
    alert={
      <SiteNotification>
        Työmarkkinatorilla on vakava häiriötilanne. Selvitämme vikaa
        parhaillaan. <Link as={LinkMock}>Lue lisää häiriötilanteesta</Link>
      </SiteNotification>
    }
    fixed={false}
  />
)

export { SiteNotificationExample2 }
