import React from 'react'
import styled from 'styled-components'

import {
  List,
  ListItem,
  Paragraph,
  SiteNotification,
  SiteNotifications
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'
import { Content } from '../../components/Content'
import { Link } from '../../components/Link'

import { SiteNotificationExample1 } from '../../examples/react/SiteNotificationExample1'
import { SiteNotificationExample2 } from '../../examples/react/SiteNotificationExample2'
import { SiteNotificationExample3 } from '../../examples/react/SiteNotificationExample3'
import { SiteNotificationHTMLExample1 } from '../../examples/html/SiteNotificationHTMLExample1'
import { SiteNotificationHTMLExample2 } from '../../examples/html/SiteNotificationHTMLExample2'
import { SiteNotificationExampleBottom } from '../../examples/html/SiteNotificationExampleBottom'

const SiteNotificationWrapper = styled.div`
  & > * {
    position: relative;
    z-index: 0;
  }
`

const Page = () => (
  <Content
    heading="SiteNotifications"
    components={[
      { component: SiteNotifications },
      { component: SiteNotification }
    ]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section title="Käyttö">
      <Paragraph noMargin>
        <Code>SiteNotifications</Code>-komponentin sisään lisätään yksi tai
        useampi <Code>SiteNotification</Code> ja/tai sille määritetään
        kriittinen häiriötiedote alert-propertyn kautta. Yläreunuksen väri
        muuttuu punaiseksi kun alert on annettu. Kun tiedotteita on useampi, ne
        piilotetaan alasvetovalikkoon mutta varoitus on aina näkyvissä.
      </Paragraph>
    </Section>

    <Section title="Yksittäinen tiedote">
      <Playground
        format="react"
        enableOverflow
        example={SiteNotificationExample1}
        WrapperComponent={SiteNotificationWrapper}
      />
      <Playground
        format="html"
        enableOverflow
        example={SiteNotificationHTMLExample1}
        WrapperComponent={SiteNotificationWrapper}
      />
    </Section>
    <Section title="Varoitus">
      <Playground
        format="react"
        enableOverflow
        example={SiteNotificationExample2}
        WrapperComponent={SiteNotificationWrapper}
      />
    </Section>
    <Section title="Varoitus sekä muita tiedotteita">
      <Playground
        format="react"
        enableOverflow
        example={SiteNotificationExample3}
        WrapperComponent={SiteNotificationWrapper}
      />
      <Playground
        format="html"
        enableOverflow
        example={SiteNotificationHTMLExample2}
        WrapperComponent={SiteNotificationWrapper}
      />
    </Section>

    <Section title="Alapalkki">
      <Paragraph>Evästebanneria käytetään vain verkkopalvelussa.</Paragraph>
      <Playground
        format="html"
        enableOverflow
        example={SiteNotificationExampleBottom}
        WrapperComponent={SiteNotificationWrapper}
      />
    </Section>

    <Section title="Saavutettavuus">
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Varmista, että komponentin teksti on kuvaava ja kertoo ytimekkäästi
          viestin. Komponentti ei saa viedä liikaa tilaa näytöltä pitkän viestin
          vuoksi.
        </ListItem>
        <ListItem>
          Jotta tärkeät ilmoitukset luetaan ruudunlukijalla heti sivulle
          saavuttaessa, varmista että <Code>SiteNotifications</Code> on
          sijoitettu sivun alkuun <Code>header</Code> -elementin sisään,{' '}
          <Code>
            <Link page="SkipLink" />
          </Code>
          in perään.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
