import React, { useState } from 'react'

import { LinkMock } from '../../components/LinkMock'

import ButtonHTMLExample from './ButtonHTMLExample'

const SiteNotificationExampleBottom = () => {
  const [show, setShow] = useState(true)

  const onClick = () => setShow(false)

  return show ? (
    <div className="site-notification site-notification-bottom">
      <div className="site-notification-row">
        <p className="mb-0">
          Käytämme evästeitä varmistaaksemme, että palvelun käyttö on sinulle
          sujuvaa ja tietoturvallista. Keräämme evästeiden avulla myös
          käyttäjäanalytiikkaa kehittämisen tueksi. Voit halutessasi hyväksyä
          vain käytön kannalta välttämättömät evästeet.{' '}
          <LinkMock>Lue lisää evästeistä</LinkMock>
        </p>
        <ButtonHTMLExample
          className="site-notification-button"
          onClick={onClick}
          size="xs"
          variant="outline"
        >
          Hyväksy vain välttämättömät
        </ButtonHTMLExample>
        <ButtonHTMLExample
          className="site-notification-button"
          color="secondary"
          onClick={onClick}
          size="xs"
        >
          Hyväksy kaikki
        </ButtonHTMLExample>
      </div>
    </div>
  ) : null
}

export { SiteNotificationExampleBottom }
