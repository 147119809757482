import React from 'react'
import { Link, SiteNotification, SiteNotifications } from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const SiteNotificationExample3 = () => (
  <SiteNotifications
    alert={
      <SiteNotification>
        Asiantuntijan työmarkkinatorilla on tietoliikennehäiriö. Tilannetta
        korjataan. Pahoittelemme häiriöstä aiheutuvaa haittaa.{' '}
        <Link as={LinkMock}>Lue lisää häiriötilanteesta</Link>
      </SiteNotification>
    }
    fixed={false}
  >
    <SiteNotification>
      Suunniteltu huoltokatko ma 18.3.2023 klo 15-23. Pahoittelemme katkosta
      aiheutuvaa haittaa.
    </SiteNotification>
    <SiteNotification>
      Heikko tunnistautuminen poissa käytöstä Karkauspäivänä 29.2.2024.
    </SiteNotification>
  </SiteNotifications>
)

export { SiteNotificationExample3 }
