import React, { useEffect } from 'react'

import { LinkMock } from '../../components/LinkMock'

import IconHTMLExample from './IconHTMLExample'

const SiteNotificationHTMLExample2 = () => {
  useEffect(() => {
    // @ts-expect-error
    window.StyleGuide.initializeToggle()
  }, [])
  return (
    <div className="site-notification site-notification-danger">
      <div className="site-notification-row">
        <IconHTMLExample
          className="site-notification-icon"
          color="danger"
          name="ErrorIcon"
          size="lg"
        />
        <p className="mb-0">
          Asiantuntijan työmarkkinatorilla on tietoliikennehäiriö. Asiaa
          korjataan.
        </p>
      </div>
      <div className="site-notification-row">
        <IconHTMLExample
          className="site-notification-icon"
          color="secondary"
          name="AlertCircleIcon"
          size="lg"
        />
        <p
          className="text-weight-semi-bold mb-0"
          id="show-more-notifications"
        >
          Häiriötiedotteet (2 kpl)
        </p>
        <button
          aria-labelledby="site-notification-collapse-button show-more-notifications"
          aria-controls="site-notification-collapse"
          aria-expanded="false"
          className="btn btn-plain-secondary site-notification-collapse-button"
          data-toggle="collapse"
          id="site-notification-collapse-button"
          type="button"
        >
          <span className="btn-content">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon icon-lg site-notification-collapse-icon-expand"
              data-icon="chevron-down"
              role="presentation"
            >
              <path
                d="m18 9-6 6-6-6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="icon icon-lg site-notification-collapse-icon-collapse"
              data-icon="chevron-up"
              role="presentation"
            >
              <path
                d="m6 15 6-6 6 6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </button>
      </div>
      <div
        aria-labelledby="site-notification-collapse-button"
        className="site-notification-drawer collapse"
        id="site-notification-collapse"
      >
        <ul className="site-notification-list">
          <li className="site-notification-list-item">
            Suunniteltu huoltokatko ma 1.7.2019 klo 15-23. Pahoittelemme
            katkosta aiheutuvaa haittaa.{' '}
            <LinkMock>Lue lisää päivityksistä</LinkMock>
          </li>
          <li className="site-notification-list-item">
            Heikko tunnistautuminen poissa käytöstä karkauspäivänä 29.2.2024.
          </li>
        </ul>
      </div>
    </div>
  )
}

export { SiteNotificationHTMLExample2 }
