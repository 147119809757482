import React from 'react'
import {
  Link,
  SiteNotification,
  SiteNotifications,
  Text
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const SiteNotificationExample1 = () => (
  <SiteNotifications fixed={false}>
    <SiteNotification>
      Suunniteltu huoltokatko{' '}
      <Text fontWeight="semi-bold">ma 18.3.2023 klo 15-23</Text>. Pahoittelemme
      katkosta aiheutuvaa haittaa.{' '}
      <Link as={LinkMock}>Lue lisää päivityksistä</Link>
    </SiteNotification>
  </SiteNotifications>
)

export { SiteNotificationExample1 }
