import React from 'react'

import { LinkMock } from '../../components/LinkMock'

import IconHTMLExample from './IconHTMLExample'

const SiteNotificationHTMLExample1 = () => (
  <div className="site-notification">
    <div className="site-notification-row">
      <IconHTMLExample
        className="site-notification-icon"
        color="secondary"
        name="AlertCircleIcon"
        size="lg"
      />
      <p className="mb-0">
        Suunniteltu huoltokatko <strong>ma 1.7.2019 klo 15-23</strong>.
        Pahoittelemme katkosta aiheutuvaa haittaa.{' '}
        <LinkMock>Lue lisää päivityksistä</LinkMock>
      </p>
    </div>
  </div>
)

export { SiteNotificationHTMLExample1 }
